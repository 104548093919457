<template>
  <div>
    <!-- <img
      style="
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        display: block;
        width: 100%;
        z-index: -1;
      "
      src="../assets/image/homepage.jpg"
    /> -->
    <headPage />
    <div>
      <div class="options" @click="jump('/newsCenter')">
        <img
          class="icon1"
          style="display: block; width: 15px"
          src="../assets/icon/return.png"
        />
        <span class="op1">新闻大栏目</span>
        <img
          class="icon2"
          style="display: block; width: 15px"
          src="../assets/icon/arrow-right.png"
        />
        <span class="op2">新闻分类名</span>
      </div>
      <div class="title">
        <p class="p1">{{ detail.title1 }}</p>
        <p class="p2">{{ detail.title2 }}</p>
      </div>
      <div class="subtitle">
        <p class="p3">
          <span>作者：</span>
          <span class="name">{{ detail.author }}</span>
          <span class="line">|</span>
          <span class="name">{{ detail.time }}</span>
          <span class="line">|</span>
          <span>暂无评论</span>
        </p>
      </div>
      <div class="content">
        <img class='img1' style='display: block; width: 85%' src='../assets/image/newsCenter/image2.jpeg' />
        <p class='annotation'>{{ detail.imgTitle }}</p>
        <p>{{ detail.content }}</p>
        <p><p class='lastp'>{{ detail.lastConent }}</p>
        </p>
        <img class='img2' style='display: block;' src='../assets/image/image5.png' />
        <p class='annotation'>{{ detail.imgTitle }}</p>
        
        <iframe :src="src" frameborder="0"></iframe>
        <div>
          <span class="up">上一篇:</span>
          <span class="down">下一篇:</span>
        </div>
      </div>
    </div>
    <footPage />
  </div>
</template>
<script>
import headPage from "@/components/head.vue";
import footPage from "@/components/foot.vue";
import newsDetile from "@/json/newsDetile.json";

export default {
  data() {
    return {
      list: newsDetile.newsList,
      detail: {},
      src: "",
    };
  },
  mounted() {
    this.detail = this.list[this.$route.query.index];
    this.src = `public/html/${this.$route.query.index}.html`;
  },
  methods: {
    jump(url) {
      this.$router.push(url);
    },
  },
  components: {
    headPage,
    footPage,
  },
};
</script>
<style lang="scss" scoped>
.up {
  cursor: pointer;
  color: red;
}
.down {
  cursor: pointer;
  margin-left: 733px;
  color: red;
}
.title {
  font-family: "SourceHanSansCN-Medium";
  font-size: 67px;
  color: black;
  margin-top: 106px;
  text-align: center;
}
.p2 {
  margin-top: -25px;
}
.p3 {
  margin-top: 25px;
}
.subtitle {
  font-family: "SourceHanSansCN-Medium";
  font-size: 20px;
  color: black;
  text-align: center;
}
.name {
  margin-right: 20px;
}
.line {
  margin-right: 20px;
}
.content {
  font-family: "SourceHanSansCN-Medium";
  font-size: 25px;
  color: #333;
  margin-top: 70px;
  margin-top: 60px;
  margin-left: 259px;
  margin-right: 259px;
  text-align: justify;
}
.img1 {
  display: block;
  height: 450px;
  margin-top: 14px;
  margin-left: 81px;
}
.annotation {
  text-align: center;
  font-size: 14px;
  color: #696969;
  margin-top: 10px;
  margin-bottom: 60px;
}
.lastp {
  margin-top: 70px;
}
.img2 {
  width: 100%;
  margin-top: 37px;
  margin-left: 5px;
}
.options {
  position: absolute;
  top: 122px;
  left: 144px;
  width: 500px;
}
.op1 {
  position: absolute;
  left: 26px;
  font-size: 19px;
  top: -7px;
  color: #333;
  font-family: "SourceHanSansCN-Medium";
  cursor: pointer;
}
.icon2 {
  position: absolute;
  left: 131px;
  top: 3px;
}
.op2 {
  position: absolute;
  left: 159px;
  font-size: 19px;
  top: -7px;
  color: #333;
  font-family: "SourceHanSansCN-Medium";
}
@media (max-width: 500px) {
  .options {
    margin-top: 120px;
    margin-left: -80px;
    .op1 {
      margin-left: 50px;
      font-size: 30px;
      margin-top: 8px;
    }
    .icon2 {
      position: absolute;
      left: 250px;
    }
    .op2 {
      margin-left: 180px;
      font-size: 30px;
      margin-top: 8px;
    }
  }
}
</style>